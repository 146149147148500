.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print {
  header {
    display: none;
  }
  img, pre {
    break-inside: avoid-page;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  .container{
    max-width: 100% !important;
  }
  
}

@page {
  size: letter landscape;
  margin: 0;
  padding: 0;
  -webkit-print-color-adjust: exact;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.survey {
  margin-bottom: 20vw;
}

.footer {
  margin-bottom: -14px;
}

.footer-score-gauges {
  width: 33%;
  text-align: center;
}

.btn-green {
  background-color: #1ab394 !important;
  color: #fff !important;
  border-radius: 3px !important;
}
.btn-green:focus,
.btn-green:hover {
  background-color: #18a689;
  color: #fff;
}
.panel-footer {
  padding: 0 15px;
  border: none;
  text-align: right;
  background-color: #fff;
}

.sv_qstn .sq-root {
  border: 1px solid gray;
  border-left: 4px solid #18a689;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}

.sv_qstn .sq-root-cb {
  border-left: 4px solid orange;
}

.sq-title {
  font-size: 20px;
  margin-left: 20px;
}

.sq-title-required {
  color: red;
}

.sq-label {
  margin-left: 30px;
}
.sq-item:nth-child(1) {
  margin-bottom: 5px;
}

.autoEvaluationFinalBackground-text {
  /* Text color */
  color: rgba(0, 0, 0, 0.2);

  /* Text styles */
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;

  /* Rotate the text */
  transform: rotate(-45deg);

  /* Disable the selection */
  user-select: none;
}

.bgtext:before {
  margin: 3rem;
  content: "Background text";
  position: absolute;
  transform: rotate(300deg);
  -webkit-transform: rotate(300deg);
  color: rgb(187, 182, 182);
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  font-size: xx-large;
}

.back {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='300px'><text x='0' y='15' fill='silver' font-size='20'>Documento no válido</text></svg>");
}

.certificateBackground {
  background-color: #0090a3 !important;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.certificateBackgroundCPR {
  background-color: #66C9BA !important;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.certificateBackgroundWhite {
  background-color: white !important;
}

.certificateSeal {
  border-radius: 25px;
  border: 2px solid #0090a3;
  margin: 0.5rem 1rem;
  flex: auto;
}

.certificateSealCPR {
  border-radius: 25px;
  border: 2px solid #66C9BA;
  margin: 0.5rem 1rem;
  flex: auto;
}

.certificateTitle {
  padding: 0.5rem 1rem;
}

.certificateICIPCLogo {
  max-width: 60%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.certificateDetail {
  display: flex;
  width: 100%;
}

.certificateProductImage {
  margin-top: 1rem;
}

.row-eq-height {
  height: 90% !important;
}